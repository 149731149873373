import React, {useState, useEffect} from "react";
import "typeface-lato";

import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";

import RootElement from "./root-element";

import {RecoilRoot} from "recoil";
import {recoilPersist} from "recoil-persist";

import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";

import {ElevioProvider} from "./src/state/elevioContext";

recoilPersist({
    key: "moris", // this key is used to store data in local storage
    storage: localStorage, // configure which storage will be used to store the data
});

const ElevioWrapper = ({element, pageContext}) => {
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const elevioArticleId = params.get("elevioArticleId");
        const elevioCategoryId = params.get("elevioCategoryId");
        const elevioFAQ = params.get("elevioFaq");

        console.log("window.location.href", window.location.href);

        const checkElev = () => {
            if (
                window._elev &&
                typeof window._elev.setLanguage === "function" &&
                typeof window?.location !== "undefined"
            ) {
                window._elev.setLanguage(window.location.href.split("/").at(-2));
            }
            if (elevioArticleId && window._elev && typeof window._elev.openArticle === "function") {
                window._elev.openArticle(elevioArticleId);
                clearInterval(intervalId);
            } else if (elevioFAQ && window._elev && typeof window._elev.openHome === "function") {
                window._elev.openHome();
                clearInterval(intervalId);
            } else if (elevioCategoryId && window._elev && typeof window._elev.openCategory === "function") {
                window._elev.openCategory(elevioCategoryId);
                clearInterval(intervalId);
            } else if (window._elev && typeof window._elev.openArticle === "function") {
                clearInterval(intervalId);
            }
        };

        const intervalId = setInterval(checkElev, 1000); // Adjust interval as needed

        checkElev();

        return () => clearInterval(intervalId);
    }, [pageContext]);

    return <>{element}</>;
};

export const wrapRootElement = ({element, pageContext}) => {
    const queryClient = new QueryClient();

    return (
        <RootElement>
            <QueryClientProvider client={queryClient}>
                {/* {element} */}
                <ElevioWrapper element={element} pageContext={pageContext} />
            </QueryClientProvider>
        </RootElement>
    );
};

export const wrapPageElement = ({element}) => {
    let locale = element?.props?.pageContext?.locale;

    if (locale === "se") {
        locale = "sv";
    }

    return (
        <RecoilRoot>
            <ElevioProvider language={locale}>{element}</ElevioProvider>
            {/* {element} */}
            <ReactQueryDevtools buttonPosition="bottom-left" position="left" initialIsOpen={false} />
        </RecoilRoot>
    );
};
